.remote-participant {
  height: 100%;
  position: relative;
  height: 100%;
}

.remote-participant video {
  width: 100%;
  position: absolute;
  bottom: 0;
  transform: scale(-1, 1);
}

.local-participant video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(-1, 1);
}

.defaultProfile {
  width: 50%;
  height: 50%;
  border-radius: 50%;
  font-size: 100px;
  color: #fff;
  text-align: center;
  line-height: 380px;
  margin-left: 28%;
  margin-top: 30%;
}

.defaultProfileParticipant {
  width: 35%;
  height: 8%;
  border-radius: 50%;
  font-size: 50px;
  color: #fff;
  text-align: center;
  line-height: 67px;
  margin-left: 28%;
  margin-top: 375%;
}

.share-screen video {
  transform: unset !important;
}
